<template>
  <div id="account-dashboard" role="tabpanel" aria-labelledby="account-dashboard-tab">
    <div action="#" class="torress-form">
      <div class="torress-form-inner">
        <div class="single-input">
          <label for="account-details-oldpass">{{$i18n.Password.Current_Password}}</label>
          <input type="password" v-model='password' id="account-details-oldpass">
        </div>
        <div class="single-input">
          <label for="account-details-newpass">{{$i18n.Password.New_Password}}</label>
          <input type="password" v-model='newPassword' id="account-details-newpass">
        </div>
        <div class="single-input">
          <label for="account-details-confpass">{{$i18n.Password.Confirm_Password}}</label>
          <input type="password" v-model='passwordConfirm' id="account-details-confpass">
        </div>
        <div class="single-input">
          <button @click='onClick' class="torress-btn torress-btn_dark" type="submit"><span>
              {{loading ? $i18n.LOADING : $i18n.SAVE_CHANGE}}
            </span></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changePassword } from '@/api'
export default {
  data() {
    return {
      newPassword: '',
      passwordConfirm: '',
      password: '',
      loading: 0,
    }
  },
  methods: {
    onClick() {
      if (!this.newPassword || !this.passwordConfirm || !this.password) {
        return this.$toasted.error(this.$i18n.ERROR_INPUT)
      }
      this.loading = 1
      let par = {
        password: this.password,
        newPassword: this.newPassword
      }
      changePassword(par).then(res => {
        if (res.data.status === 'fail') {
          return this.$toasted.error(res.data.msg || this.$i18n.Password.ERROR_CHANGE)
        } else {
          // 清除input内容
          this.newPassword = ''
          this.passwordConfirm = ''
          this.password = ''
          return this.$toasted.success(res.data.msg || this.$i18n.Password.SUCCESS_CHANGE)
        }
      })
    }
  }
}

</script>
